import queryString from 'query-string'
import { storeToRefs } from 'pinia'
import { useLobbyGameApi } from '@/api/lobby-game'
import { PATH_GAME_URL } from '~/constants/path'
import { EMPTY_CONTENT } from '~/constants/lobby'
import { useGameStore } from '~~/store/game'
import { useLoading } from '~/composables/useLoading'
import { IItemCongGame, IProvider } from '~/types/lobby.type'
import { PAGE_URLS } from '~/config/page-url'
export const useCasino = () => {
  const { $device, $pinia, $axios } = useNuxtApp()
  const store = useGameStore($pinia)
  const { LOBBY_CATEGORY, LIST_LOBBY_CASINO } = useLobbyGameApi()
  const route = useRoute()
  const router = useRouter()
  const { loading, load, isLoading } = useLoading()
  const { currentRoute, providerCasino } = storeToRefs(store)

  const listCasino = ref<IItemCongGame[] | null>(null)
  const listProvider = ref<IProvider[] | null>(null)
  const titleCasino = 'LIVE CASINO'
  const allObject = {
    code: 'all',
    name: 'Tất cả',
    url: '',
    image_path: `${PATH_GAME_URL}icon-all-mb.webp`
  }

  const providerMapping: [any: string] = {
    go: 'hit'
  }
  const reversedMapping = Object.fromEntries(
    Object.entries(providerMapping).map(([key, value]) => [value, key])
  )

  const currentProvider = ref<IProvider | null>(allObject)
  const currentCategory = ref<IItemCongGame | null>(null)
  const totalItemPerPage = ref<number>(0)
  const typeQuery = computed(() => route.params?.type as string)
  const partnerQuery = computed(() => route.query?.partner as string)
  const listCategoryCasino = ref<IItemCongGame[]>([])
  const totalItem = ref<number>(0)
  const placeholderCount = ref<number>(0)

  // View More
  const limit: number = 30
  const page = ref<number>(1)
  const hasMoreGame = ref(false)
  const isLoadingMore = ref(false)
  const totalPage = ref(0)

  const initDataCasino = async () => {
    page.value = 1
    return await fetchListCasino()
  }

  const setdataCasino = (data: {
    totalItem: number
    listCasino: any[]
    totalItemPerPage: number
    providerCasino: any[]
  }) => {
    totalItem.value = data.totalItem
    totalItemPerPage.value = data.totalItemPerPage
    listCasino.value = data.listCasino
    listProvider.value = data.providerCasino
    store.setProviderCasino(data.providerCasino)
    if (providerCasino.value) {
      filterProvider(providerCasino.value)
      currentProvider.value =
        listProvider.value?.find((item: IProvider) => item?.code === route.query.provider) ||
        (listProvider.value ? listProvider.value[0] : null)
    }
  }

  const initData = async () => {
    isLoadingMore.value = true
    const gameData = await initDataCasino()
    setdataCasino({ ...gameData, providerCasino: providerCasino.value })
  }
  const handleChangeCategory = (item: IItemCongGame) => {
    currentCategory.value = item
    if (item?.alias) {
      navigateTo(`${PAGE_URLS.CASINO}/${item?.alias}`)
    }
  }
  const handleChangeProvider = (item: IProvider) => {
    currentProvider.value = item
    const _query = { ...route.query }
    if (item?.code === 'all') {
      delete _query.partner
    } else {
      const providerType = providerMapping?.[item?.code] ?? item?.code
      _query.partner = providerType
    }
    navigateTo({ query: _query })
  }
  const filterProvider = (data: IItemCongGame[]) => {
    const type = route?.params?.type as string
    const filteredItem = data.find((item) => {
      if (type) {
        return item?.alias === type
      }
      return item?.alias === 'all'
    })

    listProvider.value = filteredItem?.providers || []
    const isAllExists = listProvider.value.some((item) => item?.code === 'all')
    if (!isAllExists) {
      listProvider.value.unshift(allObject)
    }
  }
  const fetchListCasino = async (params: { type?: string; partner?: string; limit?: number } = {}) => {
    loading()
    const gameType = route?.params?.type
    let partnerProvider = params?.partner || partnerQuery.value
    partnerProvider = reversedMapping[partnerProvider] || partnerProvider
    const limitParam = params?.limit || limit
    const queryUrl = queryString.stringify({
      path_alias: gameType,
      provider_code: partnerProvider && partnerProvider !== 'all' ? partnerProvider : '',
      limit: limitParam,
      page: page.value
    })
    try {
      const { data: response } = await $axios.get(`${LIST_LOBBY_CASINO}?${queryUrl}`)
      if (response.status === 'OK') {
        if (page.value > 1 && listCasino.value) {
          listCasino.value = listCasino.value.concat(response.data?.items)
        } else {
          listCasino.value = response.data?.items
        }
        totalPage.value = response.data.total_page
        totalItem.value = response.data?.total
        totalItemPerPage.value = limit * (page.value - 1) + response.data?.items.length
        hasMoreGame.value = totalItemPerPage.value < totalItem.value
        if (typeQuery.value !== currentRoute.value || (params?.limit && params?.limit !== limit)) {
          store.setCurrentRoute(typeQuery.value)
        }
        return {
          totalItem: totalItem.value,
          totalPage: totalPage.value,
          listCasino: listCasino.value,
          totalItemPerPage: listCasino.value?.length || 0,
          hasMoreGame: hasMoreGame.value
        }
      }
    } catch (error: any) {
      console.log('error', error?.response?.data?.message)
      return {
        totalItem: totalItem.value,
        totalPage: totalPage.value,
        listCasino: listCasino.value,
        totalItemPerPage: listCasino.value?.length || 0,
        hasMoreGame: hasMoreGame.value
      }
    } finally {
      load()
    }
  }

  const calculatePlaceholder = () => {
    const totalItems = listCasino.value?.length || 0
    let itemsPerRow
    if ($device.isDesktop) {
      itemsPerRow = 5
    } else {
      itemsPerRow = 3
    }

    const remainder = totalItems % itemsPerRow
    placeholderCount.value = remainder === 0 ? 0 : itemsPerRow - remainder
    store.setPlaceholderCount(placeholderCount.value)
  }
  watch(listCasino, () => {
    if (listCasino.value && listCasino.value.length > 0) {
      calculatePlaceholder()
    }
  })

  const fetchGameCasino = async () => {
    try {
      const { data: response } = await $axios.get(`${LOBBY_CATEGORY}?alias=livecasino`)
      if (response.status === 'OK') {
        const listCategoryCasino = response?.data || []
        store.setProviderCasino(listCategoryCasino)
        filterProvider(listCategoryCasino)
      }
      return response?.data || []
    } catch (error: any) {
      console.log('error', error?.response?.data?.message)
      return []
    } finally {
      load()
    }
  }

  const loadMoreListCasino = async () => {
    try {
      page.value += 1
      isLoadingMore.value = true
      await fetchListCasino({})
      isLoadingMore.value = false
    } catch (error) {
      console.log(error)
    }
  }

  return {
    fetchListCasino,
    handleChangeProvider,
    listProvider,
    currentProvider,
    listCasino,
    isLoading,
    titleCasino,
    totalItem,
    totalItemPerPage,
    EMPTY_CONTENT,
    calculatePlaceholder,
    placeholderCount,
    currentCategory,
    typeQuery,
    partnerQuery,
    fetchGameCasino,
    listCategoryCasino,
    handleChangeCategory,
    page,
    reversedMapping,
    providerMapping,
    hasMoreGame,
    isLoadingMore,
    loadMoreListCasino,
    initData,
    initDataCasino,
    setdataCasino
  }
}
